.graphic-background-bg-illustration-gameservers {
  display: flex;
  justify-content: center;
  align-items: flex-end; }
  .graphic-background-bg-illustration-gameservers .graphic-background-left {
    margin-right: auto;
    min-width: max-content; }
    @media (max-width: 1676px) {
      .graphic-background-bg-illustration-gameservers .graphic-background-left {
        padding-right: 96px; } }
    @supports (-webkit-hyphens: none) {
      .graphic-background-bg-illustration-gameservers .graphic-background-left {
        height: intrinsic; } }
  .graphic-background-bg-illustration-gameservers .graphic-background-right {
    margin-left: auto;
    min-width: max-content; }
    @media (max-width: 1676px) {
      .graphic-background-bg-illustration-gameservers .graphic-background-right {
        padding-left: 96px; } }
    @supports (-webkit-hyphens: none) {
      .graphic-background-bg-illustration-gameservers .graphic-background-right {
        height: intrinsic; } }
  .graphic-background-bg-illustration-gameservers[data-animation-css]:not(.is-animated) [data-bg-element-animation],
  .graphic-background-bg-illustration-gameservers[data-animation-css]:not(.is-animated) [data-server-animation-bottom],
  .graphic-background-bg-illustration-gameservers[data-animation-css]:not(.is-animated) [data-server-animation-center],
  .graphic-background-bg-illustration-gameservers[data-animation-css]:not(.is-animated) [data-server-animation-top],
  .graphic-background-bg-illustration-gameservers[data-animation-css]:not(.is-animated) [data-server-animation-top-2],
  .graphic-background-bg-illustration-gameservers[data-animation-css]:not(.is-animated) [data-server-sm-animation-bottom],
  .graphic-background-bg-illustration-gameservers[data-animation-css]:not(.is-animated) [data-server-sm-animation-center],
  .graphic-background-bg-illustration-gameservers[data-animation-css]:not(.is-animated) [data-server-sm-animation-top],
  .graphic-background-bg-illustration-gameservers[data-animation-css]:not(.is-animated) [data-server-sm-animation-top-2],
  .graphic-background-bg-illustration-gameservers[data-animation-css]:not(.is-animated) [data-animation-cube],
  .graphic-background-bg-illustration-gameservers[data-animation-css]:not(.is-animated) [data-animation-coin],
  .graphic-background-bg-illustration-gameservers[data-animation-css]:not(.is-animated) [data-animation-dot],
  .graphic-background-bg-illustration-gameservers[data-animation-css]:not(.is-animated) [data-animation-path] {
    animation: unset !important; }
  .graphic-background-bg-illustration-gameservers[data-animation-css].is-paused [data-animation-cube],
  .graphic-background-bg-illustration-gameservers[data-animation-css].is-paused [data-animation-coin],
  .graphic-background-bg-illustration-gameservers[data-animation-css].is-paused [data-animation-dot],
  .graphic-background-bg-illustration-gameservers[data-animation-css].is-paused [data-animation-vertical-loop-sm],
  .graphic-background-bg-illustration-gameservers[data-animation-css].is-paused [data-animation-vertical-loop],
  .graphic-background-bg-illustration-gameservers[data-animation-css].is-paused [data-animation-up-down],
  .graphic-background-bg-illustration-gameservers[data-animation-css].is-paused [data-animation-down-up] {
    animation-play-state: paused; }
  .graphic-background-bg-illustration-gameservers[data-animation-css] [data-bg-element-animation],
  .graphic-background-bg-illustration-gameservers[data-animation-css] [data-server-animation-bottom],
  .graphic-background-bg-illustration-gameservers[data-animation-css] [data-server-animation-center],
  .graphic-background-bg-illustration-gameservers[data-animation-css] [data-server-animation-top],
  .graphic-background-bg-illustration-gameservers[data-animation-css] [data-server-animation-top-2],
  .graphic-background-bg-illustration-gameservers[data-animation-css] [data-server-sm-animation-bottom],
  .graphic-background-bg-illustration-gameservers[data-animation-css] [data-server-sm-animation-center],
  .graphic-background-bg-illustration-gameservers[data-animation-css] [data-server-sm-animation-top],
  .graphic-background-bg-illustration-gameservers[data-animation-css] [data-server-sm-animation-top-2],
  .graphic-background-bg-illustration-gameservers[data-animation-css] [data-animation-cube],
  .graphic-background-bg-illustration-gameservers[data-animation-css] [data-animation-dot],
  .graphic-background-bg-illustration-gameservers[data-animation-css] [data-animation-coin] {
    opacity: 0; }
  .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-bg-element-animation] {
    animation-name: sectionBackgroundFadeTop0-gameservers;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-timing-function: ease-in;
    animation-fill-mode: forwards; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-bg-element-animation][data-animation-type-1] {
      animation-name: sectionBackgroundFadeTop1-gameservers; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-bg-element-animation][data-animation-type-2] {
      animation-name: sectionBackgroundFadeTop2-gameservers; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-bg-element-animation][data-animation-type-3] {
      animation-name: sectionBackgroundFadeTop3-gameservers; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-bg-element-animation][data-animation-type-4] {
      animation-name: sectionBackgroundFadeTop4-gameservers; }
  .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-server-animation] {
    animation-duration: 1s;
    animation-delay: 0s;
    animation-timing-function: cubic-bezier(0.16, 0, 0, 1);
    animation-fill-mode: forwards; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-server-animation][data-server-animation-bottom] {
      animation-name: serverAnimationBottom-gameservers; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-server-animation][data-server-animation-center] {
      animation-name: serverAnimationCenter-gameservers; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-server-animation][data-server-animation-top] {
      animation-name: serverAnimationTop-gameservers; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-server-animation][data-server-animation-top-2] {
      animation-name: serverAnimationTop2-gameservers; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-server-animation][data-server-sm-animation-bottom] {
      animation-name: serverSmAnimationBottom-gameservers; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-server-animation][data-server-sm-animation-center] {
      animation-name: serverSmAnimationCenter-gameservers; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-server-animation][data-server-sm-animation-top] {
      animation-name: serverSmAnimationTop-gameservers; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-server-animation][data-server-sm-animation-top-2] {
      animation-name: serverSmAnimationTop2-gameservers; }
  .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-dot] {
    animation-duration: 3.20s;
    animation-delay: 1.08s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-dot][dot-opacity-up] {
      animation-name: dotsLoopOpacity1-gameservers; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-dot][dot-opacity-down] {
      animation-name: dotsLoopOpacity2-gameservers; }
  .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-cube] {
    will-change: transform, opacity;
    animation-name: cubeLoop-gameservers;
    animation-duration: 2.20s;
    animation-delay: 1.08s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-cube]:nth-child(1) {
      animation-delay: 0.32s; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-cube]:nth-child(2) {
      animation-delay: 0.96s; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-cube]:nth-child(3) {
      animation-delay: 1.24s; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-cube][data-animation-cube-1] {
      --cubeStart: 60px;
      --cubeGap: -50px; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-cube][data-animation-cube-2] {
      --cubeStart: 40px;
      --cubeGap: -70px; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-cube][data-animation-cube-3] {
      --cubeStart: 20px;
      --cubeGap: -70px; }
  .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-coin] {
    will-change: transform, opacity;
    animation-name: coinLoop-gameservers;
    animation-duration: 2.20s;
    animation-delay: 1.24s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-coin]:nth-child(1) {
      animation-delay: 0.32s; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-coin]:nth-child(2) {
      animation-delay: 0.96s; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-coin]:nth-child(3) {
      animation-delay: 1.56s; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-coin][data-animation-coin-1] {
      --coinStart: 50px;
      --coinGap: -50px; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-coin][data-animation-coin-2] {
      --coinStart: 70px;
      --coinGap: 0px; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-coin][data-animation-coin-3] {
      --coinStart: 30px;
      --coinGap: -90px; }
  .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-dot] {
    will-change: transform, opacity;
    animation-name: dotLoop-gameservers;
    animation-duration: 2.20s;
    animation-delay: 1.24s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-dot]:nth-child(1) {
      animation-delay: 1.24s; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-dot]:nth-child(2) {
      animation-delay: 0.32s; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-dot]:nth-child(3) {
      animation-delay: 1.56s; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-dot]:nth-child(4) {
      animation-delay: 0.56s; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-dot][data-animation-dot-1] {
      --dotStart: 50px;
      --dotGap: -20px; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-dot][data-animation-dot-2] {
      --dotStart: 20px;
      --dotGap: -30px; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-dot][data-animation-dot-3] {
      --dotStart: 60px;
      --dotGap: 0px; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-dot][data-animation-dot-4] {
      --dotStart: 20px;
      --dotGap: -20px; }
  .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-path] {
    will-change: transform, opacity;
    animation-timing-function: linear;
    animation-fill-mode: forwards; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-path][data-animation-path-1] {
      animation-name: pathAnimation1-gameservers;
      animation-duration: 1s;
      animation-delay: 2.08s; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-path][data-animation-path-2] {
      animation-name: pathAnimation2-gameservers;
      animation-duration: 1s;
      animation-delay: 1.08s; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-path][data-animation-path-3] {
      animation-name: pathAnimation3-gameservers;
      animation-duration: 1.5s;
      animation-delay: 1.08s; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-path][data-animation-path-4] {
      animation-name: pathAnimation4-gameservers;
      animation-duration: 1s;
      animation-delay: 1.08s; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-path][data-animation-path-5] {
      animation-name: pathAnimation5-gameservers;
      animation-duration: 1s;
      animation-delay: 1.08s; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-path][data-animation-path-6] {
      animation-name: pathAnimation6-gameservers;
      animation-duration: 1s;
      animation-delay: 2.08s; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-path][data-animation-path-7] {
      animation-name: pathAnimation7-gameservers;
      animation-duration: 1s;
      animation-delay: 2.08s; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-path][data-animation-path-8] {
      animation-name: pathAnimation8-gameservers;
      animation-duration: 1.3s;
      animation-delay: 1.08s; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-path][data-animation-path-9] {
      animation-name: pathAnimation9-gameservers;
      animation-duration: 1s;
      animation-delay: 1.08s; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-path][data-animation-path-10] {
      animation-name: pathAnimation10-gameservers;
      animation-duration: 1s;
      animation-delay: 1.08s; }
    .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-path][data-animation-path-11] {
      animation-name: pathAnimation11-gameservers;
      animation-duration: 1s;
      animation-delay: 2.08s; }
  .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-up-down] {
    will-change: transform, opacity;
    animation-name: upDownReverse-gameservers;
    animation-duration: 3s;
    animation-delay: 1.08s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards; }
  .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-down-up] {
    animation-name: upDown-gameservers;
    animation-duration: 3s;
    animation-delay: 1.08s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-fill-mode: forwards; }
  .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-delay-1] {
    animation-delay: 0.56s; }
  .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-delay-2] {
    animation-delay: 0.64s; }
  .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-delay-3] {
    animation-delay: 0.80s; }
  .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-delay-4] {
    animation-delay: 0.96s; }
  .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-duration-1] {
    animation-duration: 1s; }
  .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-duration-2] {
    animation-duration: 2s; }
  .graphic-background-bg-illustration-gameservers[data-animation-css].is-animated [data-animation-duration-3] {
    animation-duration: 3s; }

@keyframes sectionBackgroundFadeTop0-gameservers {
  0% {
    transform: translate3d(0px, 0px, 0px);
    opacity: 0; }
  48% {
    opacity: 1; }
  100% {
    transform: translate3d(0px, 0px, 0px);
    opacity: 1; } }

@keyframes sectionBackgroundFadeTop1-gameservers {
  0% {
    transform: translate3d(0px, 16px, 0px);
    opacity: 0; }
  48% {
    opacity: 1; }
  100% {
    transform: translate3d(0px, 0px, 0px);
    opacity: 1; } }

@keyframes sectionBackgroundFadeTop2-gameservers {
  0% {
    transform: translate3d(0px, 32px, 0px);
    opacity: 0; }
  48% {
    opacity: 1; }
  100% {
    transform: translate3d(0px, 0px, 0px);
    opacity: 1; } }

@keyframes sectionBackgroundFadeTop3-gameservers {
  0% {
    transform: translate3d(0px, 48px, 0px);
    opacity: 0; }
  48% {
    opacity: 1; }
  100% {
    transform: translate3d(0px, 0px, 0px);
    opacity: 1; } }

@keyframes sectionBackgroundFadeTop4-gameservers {
  0% {
    transform: translate3d(0px, 56px, 0px);
    opacity: 0; }
  48% {
    opacity: 1; }
  100% {
    transform: translate3d(0px, 0px, 0px);
    opacity: 1; } }

@keyframes serverAnimationBottom-gameservers {
  0% {
    opacity: 0; }
  48% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes serverAnimationCenter-gameservers {
  0% {
    transform: translate3d(0px, 16px, 0px);
    opacity: 0; }
  48% {
    opacity: 1; }
  100% {
    transform: translate3d(0px, 0px, 0px);
    opacity: 1; } }

@keyframes serverAnimationTop-gameservers {
  0% {
    transform: translate3d(0px, 32px, 0px);
    opacity: 0; }
  48% {
    opacity: 1; }
  100% {
    transform: translate3d(0px, 0px, 0px);
    opacity: 1; } }

@keyframes serverAnimationTop2-gameservers {
  0% {
    transform: translate3d(0px, 48px, 0px);
    opacity: 0; }
  48% {
    opacity: 1; }
  100% {
    transform: translate3d(0px, 0px, 0px);
    opacity: 1; } }

@keyframes serverSmAnimationBottom-gameservers {
  0% {
    opacity: 0; }
  48% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes serverSmAnimationCenter-gameservers {
  0% {
    transform: translate3d(0px, 8px, 0px);
    opacity: 0; }
  48% {
    opacity: 1; }
  100% {
    transform: translate3d(0px, 0px, 0px);
    opacity: 1; } }

@keyframes serverSmAnimationTop-gameservers {
  0% {
    transform: translate3d(0px, 16px, 0px);
    opacity: 0; }
  48% {
    opacity: 1; }
  100% {
    transform: translate3d(0px, 0px, 0px);
    opacity: 1; } }

@keyframes serverSmAnimationTop2-gameservers {
  0% {
    transform: translate3d(0px, 24px, 0px);
    opacity: 0; }
  48% {
    opacity: 1; }
  100% {
    transform: translate3d(0px, 0px, 0px);
    opacity: 1; } }

@keyframes dotsLoopOpacity1-gameservers {
  0%, 100% {
    opacity: 0.2; }
  50% {
    opacity: 1; } }

@keyframes dotsLoopOpacity2-gameservers {
  0%, 100% {
    opacity: 1; }
  50% {
    opacity: 0.2; } }

@keyframes cubeLoop-gameservers {
  0% {
    transform: translate3d(0px, var(--cubeStart), 0px);
    opacity: 0; }
  10%, 60% {
    opacity: 1; }
  to {
    transform: translate3d(0px, var(--cubeGap), 0px);
    opacity: 0; } }

@keyframes coinLoop-gameservers {
  0% {
    transform: translate3d(0px, var(--coinStart), 0px);
    opacity: 0; }
  10%, 60% {
    opacity: 1; }
  to {
    transform: translat3d(0px, var(--coinGap), 0px);
    opacity: 0; } }

@keyframes dotLoop-gameservers {
  0% {
    transform: translate3d(0px, var(--dotStart), 0px);
    opacity: 0; }
  10%, 60% {
    opacity: 1; }
  to {
    transform: translate3d(0px, var(--dotGap), 0px);
    opacity: 0; } }

@keyframes upDown-gameservers {
  0%, 100% {
    transform: translate3d(0px, 0px, 0px); }
  50% {
    transform: translate3d(0px, 15px, 0px); } }

@keyframes upDownReverse-gameservers {
  0%, 100% {
    transform: translate3d(0px, 0px, 0px); }
  50% {
    transform: translate3d(0px, -15px, 0px); } }

@keyframes pathAnimation1-gameservers {
  from {
    stroke-dashoffset: 77; }
  to {
    stroke-dashoffset: 0; } }

@keyframes pathAnimation2-gameservers {
  from {
    stroke-dashoffset: 322; }
  to {
    stroke-dashoffset: 0; } }

@keyframes pathAnimation3-gameservers {
  from {
    stroke-dashoffset: 400; }
  to {
    stroke-dashoffset: 0; } }

@keyframes pathAnimation4-gameservers {
  from {
    stroke-dashoffset: 588; }
  to {
    stroke-dashoffset: 0; } }

@keyframes pathAnimation5-gameservers {
  from {
    stroke-dashoffset: 112; }
  to {
    stroke-dashoffset: 0; } }

@keyframes pathAnimation6-gameservers {
  from {
    stroke-dashoffset: -94; }
  to {
    stroke-dashoffset: 0; } }

@keyframes pathAnimation7-gameservers {
  from {
    stroke-dashoffset: -256; }
  to {
    stroke-dashoffset: 0; } }

@keyframes pathAnimation8-gameservers {
  from {
    stroke-dashoffset: 140; }
  to {
    stroke-dashoffset: 0; } }

@keyframes pathAnimation9-gameservers {
  from {
    stroke-dashoffset: 96; }
  to {
    stroke-dashoffset: 0; } }

@keyframes pathAnimation10-gameservers {
  from {
    stroke-dashoffset: 50; }
  to {
    stroke-dashoffset: 0; } }

@keyframes pathAnimation11-gameservers {
  from {
    stroke-dashoffset: -96; }
  to {
    stroke-dashoffset: 0; } }

[dir="rtl"] .graphic-background-bottom-banner-illustration-homepage .graphic-background-left,
[dir="rtl"] .graphic-background-bg-illustration-gameservers .graphic-background-left {
  margin-left: auto;
  margin-right: 0;
  transform: scaleX(-1); }

[dir="rtl"] .graphic-background-bottom-banner-illustration-homepage .graphic-background-right,
[dir="rtl"] .graphic-background-bg-illustration-gameservers .graphic-background-right {
  margin-left: 0;
  margin-right: auto;
  transform: scaleX(-1); }
